// DO NOT EDIT MANUALLY! This file is generated from Airtable: "PowerUp-Items"

import { Value } from './powerUpItems.types';

// prettier-ignore
const powerUpItems: Value[] = [
  {
    "category": "Services",
    "condition": "pu_gear_pickaxe_lvl14",
    "description": "Experts in the field, teaching you the tricks of the trade. Become a master miner with us.",
    "id": "pu_services_mine_masters",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_mine_masters.png",
    "initialCost": 2300,
    "initialEarn": 353,
    "name": "Mine Masters",
    "sort": 11
  },
  {
    "category": "Companions",
    "description": "Knows the perfect shade of blue to improve user engagement but can't decide what to wear in the morning. Believes in form, function, and flawless",
    "id": "pu_companions_ux_designer",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_ux_designer.png",
    "initialCost": 1500,
    "initialEarn": 122,
    "name": "UX designer"
  },
  {
    "category": "Gear",
    "condition": "pu_companions_intern_lvl5",
    "description": "Carry all your essentials, from pickaxes to private keys. Be prepared for the long haul.",
    "id": "pu_gear_backpack",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_backpack.png",
    "initialCost": 550,
    "initialEarn": 61,
    "name": "Backpack"
  },
  {
    "category": "Companions",
    "condition": "pu_gear_first_aid_kit_lvl7",
    "description": "Think you 'play with computers all day.' Constantly ask if Bitcoin is Monopoly money and if you can fix their phones.",
    "id": "pu_companions_curious_inlaws",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_curious_inlaws.png",
    "initialCost": 4000,
    "initialEarn": 352,
    "name": "Curious Inlaws"
  },
  {
    "category": "Gear",
    "description": "Our rigs are so cool, they bathe in oil! Keeps your equipment slick and smooth, and your profits from overheating. Just don't confuse it with your deep fryer.",
    "id": "pu_gear_oil_immersed_rigs",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_oil_immersed_rigs.png",
    "initialCost": 350,
    "initialEarn": 37,
    "name": "Oil Immersed Rig"
  },
  {
    "category": "Services",
    "description": "Digging deeper than your last Netflix binge-watch session. We find the blocks others miss.",
    "id": "pu_services_blockbuster_excavation",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_blockbuster_excavation.png",
    "initialCost": 3000,
    "initialEarn": 404,
    "name": "Blockbuster excavation",
    "sort": 12
  },
  {
    "category": "Gear",
    "description": "Protect your noggin from falling rocks and falling crypto prices. It's all about keeping your head in the game.",
    "id": "pu_gear_hardhat",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_hardhat.png",
    "initialCost": 600,
    "initialEarn": 47,
    "name": "Hard Hat"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_thermal_paste_lvl6",
    "description": "Chill out with our water-cooled PC. It’s like a spa day for your computer, ensuring your hardware stays refreshed and your mining stays efficient. Warning: Do not attempt a",
    "id": "pu_gear_water_cooled_pc",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_water_cooled_pc.png",
    "initialCost": 400,
    "initialEarn": 47,
    "name": "Water Cooled PC"
  },
  {
    "category": "Specials",
    "condition": "pu_services_rig_rentals_lvl20",
    "description": "From HODLing to securing the network. Enjoy your rewards and nerd cred: 'I validate blocks for a living.'",
    "id": "pu_specials_first_eth_validator",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_first_eth_validator.png",
    "initialCost": 1100,
    "initialEarn": 132,
    "name": "First ETH Validator"
  },
  {
    "category": "Services",
    "description": "Show off your digital masterpieces at our NFT Gallery. Because why hang a boring painting when you can display a $69 million pixelated cat?",
    "id": "pu_services_nft_gallery",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_nft_gallery.png",
    "initialCost": 2500,
    "initialEarn": 235,
    "name": "NFT Gallery",
    "sort": 13
  },
  {
    "category": "Companions",
    "description": "Can build a computer from spare parts but can't assemble IKEA furniture. Always tinkering, always innovating.",
    "id": "pu_companions_hardware_hacker",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_hardware_hacker.png",
    "initialCost": 2400,
    "initialEarn": 182,
    "name": "Hardware Hacker"
  },
  {
    "category": "Companions",
    "description": "Still prefers cash and thinks crypto is a passing fad. Asks you daily when you'll sell 'that funny internet money.'",
    "id": "pu_companions_skeptical_partner",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_skeptical_partner.png",
    "initialCost": 3800,
    "initialEarn": 290,
    "name": "Skeptical Partner"
  },
  {
    "category": "Companions",
    "condition": "pu_gear_thermal_paste_lvl10",
    "description": "Invests in startups with a 100-slide pitch deck. Has a sixth sense for exits and a soft spot for founders with big dreams.",
    "id": "pu_companions_venture_capitalist",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_venture_capitalist.png",
    "initialCost": 5500,
    "initialEarn": 402,
    "name": "Venture Capitalist"
  },
  {
    "category": "Gear",
    "description": "Stay visible in the mine and the market. Let others see your shine.",
    "id": "pu_gear_reflective_vest",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_reflective_vest.png",
    "initialCost": 700,
    "initialEarn": 61,
    "name": "Reflective Vest"
  },
  {
    "category": "Companions",
    "description": "Buys high, sells low, and swears they'll get it right next time. Their portfolio is as volatile as their mood swings.",
    "id": "pu_companions_crypto_daytrader",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_crypto_daytrader.png",
    "initialCost": 4900,
    "initialEarn": 452,
    "name": "Crypto Daytrader"
  },
  {
    "category": "Gear",
    "description": "Keep your feet firmly on the ground, even when your crypto is mooning.",
    "id": "pu_gear_sturdy_boots",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_sturdy_boots.png",
    "initialCost": 750,
    "initialEarn": 81,
    "name": "Sturdy Boots"
  },
  {
    "category": "Services",
    "description": "Need a rig for a day or a decade? We've got you covered. No long-term commitment required.",
    "id": "pu_services_rig_rentals",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_rig_rentals.png",
    "initialCost": 4000,
    "initialEarn": 401,
    "name": "Rig Rentals",
    "sort": 14
  },
  {
    "category": "Companions",
    "description": "Lives in a hoodie and swears by intermittent fasting. Can deadlift 300 pounds but still calls mom to figure out the laundry.",
    "id": "pu_companions_techbro",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_techbro.png",
    "initialCost": 6000,
    "initialEarn": 803,
    "name": "Tech Bro"
  },
  {
    "category": "Services",
    "description": "Trade your tokens like a pro with our Swap Services. It's like a magical vending machine that turns your snack tokens into gourmet crypto cuisine.",
    "id": "pu_services_swap_services",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_swap_services.png",
    "initialCost": 3600,
    "initialEarn": 442,
    "name": "Swap Services",
    "sort": 15
  },
  {
    "category": "Companions",
    "condition": "pu_specials_denver_eth_conference_lvl1",
    "description": "Writes about the latest gadget but secretly uses a flip phone. Always on deadline, always in the know.",
    "id": "pu_companions_tech_journalist",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_tech_journalist.png",
    "initialCost": 6100,
    "initialEarn": 503,
    "name": "Tech Journalist"
  },
  {
    "category": "Gear",
    "description": "Keep your CPU cool and efficient. Smooth operation for smooth transactions.",
    "id": "pu_gear_thermal_paste",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_thermal_paste.png",
    "initialCost": 650,
    "initialEarn": 62,
    "name": "Thermal Paste"
  },
  {
    "category": "Services",
    "description": "Lifting heavy loads and heavy-hearted traders since 2010. We reach the heights others dream of.",
    "id": "pu_services_crypto_crane",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_cryptocrane.png",
    "initialCost": 2500,
    "initialEarn": 354,
    "name": "Crypto Crane",
    "sort": 16
  },
  {
    "category": "Gear",
    "description": "Climb to new heights, in mines and market cap. Don't forget to secure your gains.",
    "id": "pu_gear_climbing_rope",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_climbing_rope.png",
    "initialCost": 800,
    "initialEarn": 67,
    "name": "Climbing Rope"
  },
  {
    "category": "Services",
    "description": "Need to haul heavy loads or stubbornly hodl your coins? Rent a trusty crypto mule! Stronger than your average wallet and way more entertaining.",
    "id": "pu_services_mule_rentals",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_mule_rentals.png",
    "initialCost": 4200,
    "initialEarn": 404,
    "name": "Mule Rentals",
    "sort": 17
  },
  {
    "category": "Services",
    "condition": "pu_services_hash_hounds_inv7",
    "description": "Sniffing out the best mining opportunities like a dog with a bone. We're on the trail 24/7.",
    "id": "pu_services_hash_hounds",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_hash_hounds.png",
    "initialCost": 2700,
    "initialEarn": 335,
    "name": "Hash Hounds",
    "sort": 18
  },
  {
    "category": "Services",
    "description": "Our Mine Canaries will tweet a warning before your mining rig hits trouble. Perfect for detecting toxic gas and market crashes. Canary songs included!",
    "id": "pu_services_mine_canaries",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_mine_canaries.png",
    "initialCost": 3400,
    "initialEarn": 358,
    "name": "Mine Canaries",
    "sort": 19
  },
  {
    "category": "Companions",
    "description": "Sees blockchain as the solution to everything, from global finance to cooking dinner. Can explain the technology but can't boil an egg.",
    "id": "pu_companions_blockchain_evangelist",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_blockchain_evangelist.png",
    "initialCost": 6900,
    "initialEarn": 904,
    "name": "Blockchain Evangelist"
  },
  {
    "category": "Companions",
    "description": "Juggles more deadlines than cups of coffee, which is saying something. Dreams in Gantt charts and wakes up to Slack notifications.",
    "id": "pu_companions_wired_pm",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_wired_pm.png",
    "initialCost": 2000,
    "initialEarn": 223,
    "name": "Wired PM"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_backup_inv3",
    "description": "When the power goes out, your mining doesn't. Our UPS Backup keeps your rigs running through blackouts and brownouts. It's like a superhero cape for your power supply.",
    "id": "pu_gear_ups_backup",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_ups_backup.png",
    "initialCost": 800,
    "initialEarn": 77,
    "name": "UPS Backup"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_o2_tank_lvl1",
    "description": "Patch up minor injuries and financial wounds. Recovery is part of the journey.",
    "id": "pu_gear_first_aid_kit",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_first_aid_kit.png",
    "initialCost": 850,
    "initialEarn": 67,
    "name": "First Aid Kit"
  },
  {
    "category": "Companions",
    "condition": "pu_companions_wired_pm_lvl10",
    "description": "Lives by the Scrum guide and believes everything can be solved with a stand-up meeting. Knows all the buzzwords but not everyone's names.",
    "id": "pu_companions_agile_coach",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_agile_coach.png",
    "initialCost": 12500,
    "initialEarn": 1102,
    "name": "Agile Coach"
  },
  {
    "category": "Gear",
    "description": "Handle your tools and your tokens with care. No one likes blisters or bad trades",
    "id": "pu_gear_protective_gloves",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_protective_gloves.png",
    "initialCost": 700,
    "initialEarn": 57,
    "name": "Protective Gloves"
  },
  {
    "category": "Specials",
    "condition": "pu_companions_early_adopter_sibling_lvl5",
    "description": "Congrats, digital prospector! You’ve struck virtual gold. Don your hard hat and celebrate.",
    "id": "pu_specials_mined_first_bitcoin",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_mined_first_bitcoin.png",
    "initialCost": 2700,
    "initialEarn": 213,
    "name": "Mined FIrst Bitcoin"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_tool_bag_lvl9",
    "description": "Store your earnings safely with a hardware wallet. Cold storage for your hot gains.",
    "id": "pu_gear_hardware_wallet",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_hardware_wallet.png",
    "initialCost": 500,
    "initialEarn": 57,
    "name": "Hardware Wallet"
  },
  {
    "category": "Companions",
    "description": "Bought Bitcoin in 2010 but lost their wallet keys. Constantly reminds you of 'what could have been.'",
    "id": "pu_companions_early_adopter_sibling",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_early_adopter_sibling.png",
    "initialCost": 6000,
    "initialEarn": 702,
    "name": "Early Adopter Sibling"
  },
  {
    "category": "Gear",
    "description": "Prevent falls, both in mines and in the volatile world of crypto trading. Secure yourself!",
    "id": "pu_gear_harness",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_harness.png",
    "initialCost": 900,
    "initialEarn": 97,
    "name": "Safety Harness"
  },
  {
    "category": "Services",
    "description": "A sanctuary for miners. Relax, unwind, and let us handle the hashes.",
    "id": "pu_services_hash_haven",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_hash_haven.png",
    "initialCost": 4150,
    "initialEarn": 385,
    "name": "Hash Haven",
    "sort": 20
  },
  {
    "category": "Companions",
    "description": "Has founded 10 startups, three of which made it past the first round of funding. Still believes the next big idea is just around the corner.",
    "id": "pu_companions_serial_entrepreneur",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_serial_entrepreneur.png",
    "initialCost": 8000,
    "initialEarn": 803,
    "name": "Serial Entrepeneur"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_bitcoin_etf_inv5",
    "description": "Bitcoin goes mainstream. Now your grandma can buy Bitcoin without losing her keys. Wall Street, meet Satoshi.",
    "id": "pu_specials_bitcoin_etf",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_bitcoin_etf.png",
    "initialCost": 4000,
    "initialEarn": 401,
    "name": "Bitcoin ETH",
    "startTime": 1718668800000
  },
  {
    "category": "Companions",
    "condition": "pu_gear_water_bottle_lvl8",
    "description": "Attends every blockchain event and loves collecting swag. Always excited about the latest industry gossip.",
    "id": "pu_companions_cryptoconference_junkie",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_cryptoconference_junkie.png",
    "initialCost": 1200,
    "initialEarn": 112,
    "name": "Cryptoconference Junkie"
  },
  {
    "category": "Specials",
    "description": "For tech neck and sore tapping muscles. Keeping your body as flexible as your crypto portfolio.",
    "id": "pu_specials_inhouse_physical_therapist",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_inhouse_physical_therapist.png",
    "initialCost": 3800,
    "initialEarn": 402,
    "name": "Inhouse Physical Therapist"
  },
  {
    "category": "Companions",
    "description": "Turns coffee and data into insights. Speaks in numbers and probabilities, but couldn't tell you where they left their keys.",
    "id": "pu_companions_data_scientist",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_data_scientist.png",
    "initialCost": 9000,
    "initialEarn": 853,
    "name": "Data Scientist"
  },
  {
    "category": "Services",
    "description": "Guarding your operations against threats. We keep the bad actors out and the good hashes in.",
    "id": "pu_services_block_bouncers",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_block_bouncers.png",
    "initialCost": 1900,
    "initialEarn": 244,
    "name": "Block Bouncers",
    "sort": 21
  },
  {
    "category": "Gear",
    "description": "Protect your knees from harsh terrain and endless hours of market analysis. Comfort matters.",
    "id": "pu_gear_knee_pads",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_knee_pads.png",
    "initialCost": 1000,
    "initialEarn": 102,
    "name": "Knee Pads"
  },
  {
    "category": "Specials",
    "description": "Invest in Ethereum without needing a tech degree. Finally, ‘gas fees’ make sense to everyone!",
    "id": "pu_specials_eth_etf",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_eth_etf.png",
    "initialCost": 1200,
    "initialEarn": 158,
    "name": "Ethereum ETF"
  },
  {
    "category": "Gear",
    "condition": "pu_services_hash_hackers_lvl8",
    "description": "Stay connected no matter where you are on the planet. Get crypto signals from space, literally",
    "id": "pu_gear_satellite_modem",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_satellite_modem.png",
    "initialCost": 2000,
    "initialEarn": 202,
    "name": "Satellite Modem"
  },
  {
    "category": "Companions",
    "condition": "pu_gear_hand_radio_lvl8",
    "description": "Works from a beach in Bali, but the WiFi is always sketchy. Balances conference calls with coconut drinks and sunsets.",
    "id": "pu_companions_digital_nomad",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_digital_nomad.png",
    "initialCost": 10000,
    "initialEarn": 904,
    "name": "Digital Nomad"
  },
  {
    "category": "Gear",
    "description": "Stay connected with your mining team and crypto community. Communication is key.",
    "id": "pu_gear_hand_radio",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_handheld_radio.png",
    "initialCost": 3000,
    "initialEarn": 304,
    "name": "Hand Radio"
  },
  {
    "category": "Specials",
    "description": "Ethereum enthusiasts in hoodies, live coding, and ‘to the moon’ speeches. Don’t miss the VR after-party!",
    "id": "pu_specials_denver_eth_conference",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_denver_eth_conference.png",
    "initialCost": 4340,
    "initialEarn": 353,
    "name": "Denver ETH conference"
  },
  {
    "category": "Gear",
    "description": "Stay hydrated while you mine blocks and blockchain data. Refresh yourself.",
    "id": "pu_gear_water_bottle",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_water_bottle.png",
    "initialCost": 1600,
    "initialEarn": 152,
    "name": "Water Bottle"
  },
  {
    "category": "Services",
    "description": "Ethically hacking the hardest blocks. We break through barriers, not laws.",
    "id": "pu_services_hash_hackers",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_hacking_services.png",
    "initialCost": 5000,
    "initialEarn": 503,
    "name": "Hash Hackers",
    "sort": 22
  },
  {
    "category": "Companions",
    "condition": "pu_gear_first_aid_kit_lvl7",
    "description": "Worries about the energy consumption of Bitcoin mining. Asks if your mining rig is eco-friendly.",
    "id": "pu_companions_concerned_enviromentalist",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_concerned_environmentalist.png",
    "initialCost": 5000,
    "initialEarn": 657,
    "name": "Concerned Environmentalist"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_gas_mask_lvl4",
    "description": "Illuminate your path to riches; don't mine in the dark unless you want to trip over your own ambition.",
    "id": "pu_gear_lamp",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_lamp.png",
    "initialCost": 1800,
    "initialEarn": 159,
    "name": "Lamp"
  },
  {
    "category": "Gear",
    "condition": "pu_companions_techbro_lvl4",
    "description": "Keep your tools and your crypto strategies within reach. Organization is key.",
    "id": "pu_gear_tool_bag",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_tool_bag.png",
    "initialCost": 3500,
    "initialEarn": 353,
    "name": "Tool Bag"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_cold_weather_gear_1morefriend",
    "description": "Brave the chill of underground mines and crypto winters. Keep warm and hodl.",
    "id": "pu_gear_cold_weather_gear",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_cold_weather_gear.png",
    "initialCost": 4000,
    "initialEarn": 353,
    "name": "Cold Weather gear"
  },
  {
    "category": "Specials",
    "description": "Celebrating the life of the Shiba that started a movement. We will miss you.",
    "endTime": 1719187200000,
    "id": "pu_specials_remembering_doge",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_remembering_doge.png",
    "initialCost": 1400,
    "initialEarn": 169,
    "name": "Remembering Doge",
    "startTime": 1717761600000
  },
  {
    "category": "Services",
    "description": "Fixing broken rigs and broken dreams. We get you back to mining in no time.",
    "id": "pu_services_rig_menders",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_rig_menders.png",
    "initialCost": 6000,
    "initialEarn": 610,
    "name": "Rig Menders",
    "sort": 23
  },
  {
    "category": "Companions",
    "condition": "pu_companions_awkward_ceo_inv10",
    "description": "Can pitch a startup to investors but struggles with small talk at the coffee machine. Social skills not included in the latest software update.",
    "id": "pu_companions_awkward_ceo",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_awkward_ceo.png",
    "initialCost": 6000,
    "initialEarn": 703,
    "name": "Awkward CEO"
  },
  {
    "category": "Companions",
    "condition": "pu_companions_wired_pm_lvl4",
    "description": "Eager to learn but mostly ends up fetching coffee and fixing printers. Hoping their next big task isn't just another TPS report.",
    "id": "pu_companions_intern",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_intern.png",
    "initialCost": 7300,
    "initialEarn": 803,
    "name": "Intern"
  },
  {
    "category": "Companions",
    "description": "Always hyping the next big drop, but can't remember where they left their digital wallet. Living life one retweet at a time.",
    "id": "pu_companions_nft_influencer",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_nft_influencer.png",
    "initialCost": 8000,
    "initialEarn": 1102,
    "name": "NFT Influencer"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_pickaxe_inv1",
    "description": "Break rocks and resistance levels with style. Your trusty tool for striking gold and crypto gems alike.",
    "id": "pu_gear_pickaxe",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_pickaxe.png",
    "initialCost": 2000,
    "initialEarn": 142,
    "name": "Pickaxe"
  },
  {
    "category": "Gear",
    "description": "Filter out hazardous gases and FUD (Fear, Uncertainty, Doubt) that could cloud your judgment.",
    "id": "pu_gear_gas_mask",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_gas_mask.png",
    "initialCost": 2500,
    "initialEarn": 223,
    "name": "Gas Mask"
  },
  {
    "category": "Gear",
    "description": "Block out the noise, whether it's a jackhammer or market chatter. Focus on your goal.",
    "id": "pu_gear_ear_protection",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_ear_protection.png",
    "initialCost": 3400,
    "initialEarn": 207,
    "name": "Ear Protection"
  },
  {
    "category": "Gear",
    "description": "Breathe easy in deep mines and deeper market crashes. Keeps you from gasping, whether for air or profits.",
    "id": "pu_gear_o2_tank",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_gear_o2_tank.png",
    "initialCost": 4100,
    "initialEarn": 252,
    "name": "O2Tank"
  },
  {
    "category": "Services",
    "condition": "pu_services_token_tutors_inv2",
    "description": "Confused by crypto? Our Token Tutors turn blockchain babble into crystal-clear concepts. They'll have you trading like a pro faster than you can say 'Satoshi Nakamoto.'",
    "id": "pu_services_token_tutors",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_services_token_tutors.png",
    "initialCost": 1400,
    "initialEarn": 123,
    "name": "Token Tutors",
    "sort": 24
  },
  {
    "category": "Specials",
    "description": "The Woodstock of blockchain. Network, catch trends, and witness new ICOs. Peace, love, and decentralization.",
    "id": "pu_specials_consensus_conference",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_consensus_conference.png",
    "initialCost": 2600,
    "initialEarn": 214,
    "name": "Consensus Conference"
  },
  {
    "category": "Companions",
    "condition": "pu_companions_reclusive_backend_dev_inv5",
    "description": "Thrives in the shadows, crafting code while avoiding sunlight and human interaction. Speaks fluent Python, not so fluent in small talk.",
    "id": "pu_companions_reclusive_backend_dev",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/sloth/pu_companions_reclusive_backend_dev.png",
    "initialCost": 15000,
    "initialEarn": 1802,
    "name": "Reclusive Backend Developer"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_gemz_on_x_quest",
    "description": "Gemz follower count on X is rising! Celebrate the milestone with this card.",
    "id": "pu_specials_gemz_on_x",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_gemz_on_x.png",
    "initialCost": 5000,
    "initialEarn": 359,
    "name": "Gemz on X"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_dao_friends",
    "description": "Add 1 more friend to unlock",
    "id": "pu_specials_dao",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_dao.png",
    "initialCost": 3400,
    "initialEarn": 342,
    "name": "+1 Friend"
  },
  {
    "category": "Specials",
    "description": "Celebrating the start of a long journey with Gemz. We are happy you are here.",
    "id": "pu_specials_new_horizons",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_new_horizons.png",
    "initialCost": 900,
    "initialEarn": 122,
    "maxLevel": 2,
    "name": "New Horizons",
    "sort": 2
  },
  {
    "category": "Specials",
    "condition": "pu_specials_ETH_vs_SEC_friends",
    "description": "ETH wins the bout! No securities here, just pure decentralized glory. Take that, SEC!",
    "endTime": 1720656000000,
    "expireTime": 1720656000000,
    "id": "pu_specials_ETH_vs_SEC",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_ETH_vs_SEC.png",
    "initialCost": 1000,
    "initialEarn": 315,
    "name": "ETH vs SEC",
    "startTime": 1720137600000
  },
  {
    "category": "Specials",
    "condition": "pu_specials_memecoins_friends",
    "description": "Investing in dog memes and frog jokes because why not? Internet humor pays, apparently!",
    "endTime": 1721433600000,
    "expireTime": 1721433600000,
    "id": "pu_specials_memecoins",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_memecoins.png",
    "initialCost": 3000,
    "initialEarn": 465,
    "name": "Memecoins",
    "startTime": 1720310400000
  },
  {
    "category": "Specials",
    "condition": "pu_specials_ethereum_merge_15friends",
    "description": "Proto-danksharding: Not a new dance craze, but it makes Ethereum groove smoother with way less energy.",
    "endTime": 1722297600000,
    "expireTime": 1722297600000,
    "id": "pu_specials_ethereum_merge",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_ethereum_merge.png",
    "initialCost": 4000,
    "initialEarn": 515,
    "name": "Ethereum Merge",
    "startTime": 1720483200000
  },
  {
    "category": "Specials",
    "condition": "pu_specials_ethereum_merge_lvl1",
    "description": "It's raining yields! Hallelujah! But also, where did my gas fees go?",
    "endTime": 1722124800000,
    "expireTime": 1722124800000,
    "id": "pu _specials_defi_explosion",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_defi_explosion.png",
    "initialCost": 0,
    "initialEarn": 535,
    "name": "DeFi Explosion",
    "startTime": 1720569600000
  },
  {
    "category": "Specials",
    "condition": "pu_specials_layer2s_1friend",
    "description": "It's like moving to the fast lane on a highway but for blockchains. No speed limits here!",
    "endTime": 1721260800000,
    "expireTime": 1721260800000,
    "id": "pu_specials_layer2s",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_layer2s.png",
    "initialCost": 5000,
    "initialEarn": 495,
    "name": "Layer 2s",
    "startTime": 1720742400000
  },
  {
    "category": "Specials",
    "condition": "pu_specials_derivatives_8friends",
    "description": "For those who like their crypto trading extra complicated. It's like calculus but with more stress.",
    "id": "pu_specials_derivatives",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_derivatives.png",
    "initialCost": 4400,
    "initialEarn": 412,
    "name": "Derivatives",
    "startTime": 1721088000000
  },
  {
    "category": "Specials",
    "condition": "pu_specials_poaps_2friends",
    "description": "Congratulations, you’ve officially turned attending virtual events into a hobby! Collect POAPs like digital badges of honor. Because nothing says 'I was there' like a blockchain souvenir that no one outside of crypto understands.",
    "endTime": 1721952000000,
    "expireTime": 1721865600000,
    "id": "pu_specials_poaps",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_poaps.png",
    "initialCost": 4500,
    "initialEarn": 565,
    "name": "POAPS",
    "startTime": 1721001600000
  },
  {
    "category": "Specials",
    "description": "As Gemz season 2 approaches, reaching magnate status will make you REALLY stand out. Who needs more earnings at this level? You just want to show off!",
    "endTime": 1724414400000,
    "id": "pu_specials_magnate_club",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_magnate_club.png",
    "initialCost": 1000000,
    "initialEarn": 60004,
    "name": "Magnate Club",
    "startTime": 1721649600000
  },
  {
    "category": "Services",
    "condition": "pu_specials_bitcoin_etf_lvl5",
    "description": "Ensure your mining operations are tighter than a miser’s wallet.",
    "id": "pu_services_blockchain_security_audit",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_services_blockchain_security_audit.png",
    "initialCost": 4100,
    "initialEarn": 305,
    "name": "Blockchain Security Audit",
    "sort": 1
  },
  {
    "category": "Services",
    "condition": "pu_services_crypto_cloud_boost_1friend",
    "description": "Rent extra cloud mining power. It’s like hiring a digital Hercules!",
    "id": "pu_services_crypto_cloud_boost",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_services_crypto_cloud_boost.png",
    "initialCost": 1980,
    "initialEarn": 203,
    "name": "Crypto Cloud Boost",
    "sort": 2
  },
  {
    "category": "Services",
    "condition": "pu_services_crypto_compliance_check_1friend",
    "description": "Ensures you stay on the right side of the law. Because orange jumpsuits aren’t flattering.",
    "id": "pu_services_crypto_compliance_check",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_services_crypto_compliance_check.png",
    "initialCost": 2100,
    "initialEarn": 205,
    "name": "Crypto Compliance Check",
    "sort": 3
  },
  {
    "category": "Services",
    "description": "Personal assistant for your mining needs. It's like having a butler for your blockchain.",
    "id": "pu_services_crypto_concierge",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_services_crypto_concierge.png",
    "initialCost": 6700,
    "initialEarn": 505,
    "name": "Crypto Concierge",
    "sort": 4
  },
  {
    "category": "Services",
    "condition": "pu_gear_satellite_modem_lvl2",
    "description": "Store your data offsite and off-grid. Perfect for the paranoid miner.",
    "id": "pu_services_decentralized_data_storage",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_services_decentralized_data_storage.png",
    "initialCost": 1200,
    "initialEarn": 109,
    "name": "Decentralized Data Storage",
    "sort": 5
  },
  {
    "category": "Services",
    "condition": "pu_services_crypto_cloud_boost_lvl1",
    "description": "Expert advice on when to hold 'em and when to fold 'em. Poker face not included.",
    "id": "pu_services_hodl_strategy_consultant",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_services_hodl_strategy_consultant.png",
    "initialCost": 3000,
    "initialEarn": 359,
    "name": "HODL Strategy Consultant",
    "sort": 6
  },
  {
    "category": "Services",
    "condition": "pu_gear_water_cooled_pc_lvl10",
    "description": "Tunes your mining setup to perfection. Efficiency meets laziness.",
    "id": "pu_services_rig_optimization_service",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_services_rig_optimization_service.png",
    "initialCost": 1400,
    "initialEarn": 122,
    "name": "Rig Optimization Service",
    "sort": 7
  },
  {
    "category": "Services",
    "condition": "pu_gear_ups_backup_lvl4",
    "description": "Easily create and deploy smart contracts without needing a law degree or a wizard's hat.",
    "id": "pu_services_smart_contract_builder",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_services_smart_contract_builder.png",
    "initialCost": 1700,
    "initialEarn": 149,
    "name": "Smart Contract Builder",
    "sort": 8
  },
  {
    "category": "Services",
    "condition": "pu_services_token_tutors_lvl 3",
    "description": "Cuts your fees down to size, like a ninja slicing through red tape.",
    "id": "pu_services_transaction_fee_negotiator",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_services_transaction_fee_negotiator.png",
    "initialCost": 3100,
    "initialEarn": 256,
    "name": "Transaction Fee Negotiator",
    "sort": 9
  },
  {
    "category": "Services",
    "condition": "pu_companions_nft_influencer_lvl4",
    "description": "Guides you through every block, like a GPS for digital gold.",
    "id": "pu_services_virtual_mining_coach",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_services_virtual_mining_coach.png",
    "initialCost": 5100,
    "initialEarn": 429,
    "name": "Virtual Mining Coach",
    "sort": 10
  },
  {
    "category": "Gear",
    "condition": "pu_companions_tech_journalist_lvl10",
    "description": "Smashes through cryptographic puzzles like your grandma’s fruitcake at Christmas.",
    "id": "pu_gear_hashrate_hammer",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_gear_hashrate_hammer.png",
    "initialCost": 4230,
    "initialEarn": 252,
    "name": "Hashrate Hammer"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_ups_backup_lvl10",
    "description": "Pulls Ethereum out of thin air, just like your favorite magic trick.",
    "id": "pu_gear_ether_extractor",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_gear_ether_extractor.png",
    "initialCost": 6600,
    "initialEarn": 501,
    "name": "Ether Extractor"
  },
  {
    "category": "Gear",
    "condition": "pu_specials_bitcoin_etf_lvl9",
    "description": "A network so small, it fits in your pocket, unlike your ambitions.",
    "id": "pu_gear_nano_node_network",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_gear_nano_node_network.png",
    "initialCost": 4400,
    "initialEarn": 502,
    "name": "Nano Node Network"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_quantum_quota_lvl6",
    "description": "Boosts your processing power and your ego simultaneously.",
    "id": "pu_gear_hashrate_enhancer_helmet",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_gear_hashrate_enhancer_helmet.png",
    "initialCost": 8200,
    "initialEarn": 803,
    "name": "Hashrate Enhancer Helmet"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_hashrate_hammer_lvl1",
    "description": "Strikes through transactions faster than you can say decentralized finance.",
    "id": "pu_gear_lighning_ledger",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_gear_lighning_ledger.png",
    "initialCost": 3050,
    "initialEarn": 252,
    "name": "Lightning Ledger"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_nano_node_network_lvl4",
    "description": "Packed with more power than a toddler on a sugar rush.",
    "id": "pu_gear_miners_motherboard",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_gear_miners_motherboard.png",
    "initialCost": 5100,
    "initialEarn": 355,
    "name": "Miner’s Motherboard"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_blockchain_blaster_inv8",
    "description": "Mines blocks faster than a hyperactive squirrel on a caffeine binge.",
    "id": "pu_gear_blockchain_blaster",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_gear_blockchain_blaster.png",
    "initialCost": 6000,
    "initialEarn": 453,
    "name": "Blockchain Blaster"
  },
  {
    "category": "Gear",
    "condition": "pu_gear_quantum_quota_1more",
    "description": "Doubles your mining capacity with a flick of a switch. It's like having two rigs in one!",
    "id": "pu_gear_quantum_quota",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_gear_quantum_quota.png",
    "initialCost": 3800,
    "initialEarn": 278,
    "name": "Quantum Quota"
  },
  {
    "category": "Gear",
    "description": "Guards your gear from electrical mishaps like a digital knight in shining armor.",
    "id": "pu_gear_power_surge_protector",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_gear_power_surge_protector.png",
    "initialCost": 7500,
    "initialEarn": 604,
    "name": "Power Surge Protector"
  },
  {
    "category": "Gear",
    "condition": "pu_services_hash_haven_lvl5",
    "description": "Crushes mining difficulties with the ease of crushing candy.",
    "id": "pu_gear_crypto_currency_crusher",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_gear_crypto_currency_crusher.png",
    "initialCost": 2500,
    "initialEarn": 335,
    "name": "Crypto Currency Crusher"
  },
  {
    "category": "Specials",
    "description": "Cai test",
    "id": "pu_specials_cai_test",
    "image": "https://img-cdn.pixlr.com/image-generator/history/65bb506dcb310754719cf81f/ede935de-1138-4f66-8ed7-44bd16efc709/medium.webp",
    "initialCost": 0,
    "initialEarn": 0,
    "name": "Cai test"
  },
  {
    "category": "Specials",
    "description": "A fresh start with new goals and challenges. Time to shine—or at least not trip at the start!",
    "id": "pu_specials_fresh_start",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_fresh_start.png",
    "initialCost": 1000,
    "initialEarn": 110,
    "name": "Fresh Start"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_socialfi_gift",
    "description": "This card will only work for a limited time before it expires. Increase your earnings with the power of social connections. This card can only be obtained and leveled with gifts.",
    "endTime": 1720483200000,
    "expireTime": 1720483200000,
    "id": "pu_specials_socialfi",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_socialfi.png",
    "initialCost": 0,
    "initialEarn": 0,
    "maxLevel": 50,
    "name": "SocialFi",
    "startTime": 1719446400000,
    "type": "giftOnly"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_stablecoins_gift",
    "description": "The calm in the crypto storm. Like having a cup of tea in the middle of a hurricane.",
    "endTime": 1720742400000,
    "expireTime": 1720742400000,
    "id": "pu_specials_stablecoins",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_stablecoins.png",
    "initialCost": 0,
    "initialEarn": 415,
    "maxLevel": 50,
    "name": "Stablecoins",
    "startTime": 1720224000000,
    "type": "giftOnly"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_bull_market_gift",
    "description": "When everything's going up, even your mood. It's like finding money in your couch cushions daily!",
    "endTime": 1721433600000,
    "expireTime": 1721433600000,
    "id": "pu_specials_bull_market",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_bull_market.png",
    "initialCost": 0,
    "initialEarn": 475,
    "maxLevel": 50,
    "name": "Bull Market",
    "startTime": 1720396817000,
    "type": "giftOnly"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_gaming_NFTs_gift",
    "description": "Collecting digital swords and rare skins. Because real life achievements are overrated.",
    "endTime": 1721347200000,
    "expireTime": 1721347200000,
    "id": "pu_specials_gaming_NFTs",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_gaming_NFTs.png",
    "initialCost": 0,
    "initialEarn": 615,
    "maxLevel": 50,
    "name": "Gaming NFTs",
    "startTime": 1720828800000,
    "type": "giftOnly"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_satoshis_silence_gift",
    "description": "The mysterious creator of Bitcoin remains anonymous. The crypto world's biggest unsolved mystery.",
    "endTime": 1723937266000,
    "expireTime": 1723937266000,
    "id": "pu_specials_satoshis_silence",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_satoshis_silence.png",
    "initialCost": 0,
    "initialEarn": 615,
    "maxLevel": 50,
    "name": "Satoshi’s Silence ",
    "sort": 3,
    "startTime": 1721347200000,
    "type": "giftOnly"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_phygitals_gift",
    "description": "For when just flexing your kicks in the real world isn't enough. Now your sneakers have a digital twin strutting their stuff in the metaverse. Twice the shoes, twice the bragging rights, half the closet space.",
    "endTime": 1723161600000,
    "id": "pu_specials_phygitals",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_phygitals.png",
    "initialCost": 0,
    "initialEarn": 302,
    "maxLevel": 50,
    "name": "Phygitals",
    "sort": 4,
    "startTime": 1721941721000,
    "type": "giftOnly"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_lambo_gift",
    "description": "When you become rich, you get to have people call you Mister Lambo",
    "endTime": 1723680000000,
    "id": "pu_specials_lambo",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_lambo.png",
    "initialCost": 0,
    "initialEarn": 252,
    "maxLevel": 30,
    "name": "Lambo",
    "sort": 2,
    "startTime": 1722384000000,
    "type": "giftOnly"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_bear_market_blues_gift",
    "description": "When your crypto portfolio hibernates like a sleepy bear. Hang tight—spring will come eventually!",
    "endTime": 1724889660000,
    "id": "pu_specials_bear_market_blues",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_bear_market_blues.png",
    "initialCost": 0,
    "initialEarn": 352,
    "maxLevel": 50,
    "name": "Bear Market Blues",
    "sort": 1,
    "startTime": 1723162293000,
    "type": "giftOnly"
  },
  {
    "category": "Specials",
    "condition": "pu_special_airdrop_gift",
    "description": "Unexpected gains are always welcome. This card can't be bought. It must be received as a gift. More gifts = discounts towards next level.",
    "endTime": 1719447293000,
    "id": "pu_specials_airdrop",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_airdrop.png",
    "initialCost": 3400,
    "initialEarn": 265,
    "maxLevel": 50,
    "name": "Airdrop",
    "startTime": 1718332321000,
    "type": "giftWithDiscount"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_friendship_chain_gift",
    "description": "A blockchain that records and rewards friendship interactions. This card can't be bought. It must be received as a gift. More gifts = discounts towards next level.",
    "expireTime": 1719964800000,
    "id": "pu_specials_friendship_chain",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_friendship_chain.png",
    "initialCost": 4200,
    "initialEarn": 362,
    "maxLevel": 50,
    "name": "Friendship Chain",
    "type": "giftWithDiscount"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_metaverse_gift",
    "description": "Navigating virtual worlds and buying digital real estate. The metaverse is your playground.",
    "endTime": 1722470400000,
    "id": "pu_specials_metaverse",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_metaverse.png",
    "initialCost": 0,
    "initialEarn": 82,
    "maxLevel": 50,
    "name": "Metaverse",
    "startTime": 1721347200000,
    "type": "giftWithDiscount"
  },
  {
    "category": "Specials",
    "description": "We loved seeing you at Edcon 2024! ",
    "endTime": 1722902399000,
    "id": "pu_specials_edcon_2024",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_edcon_2024.png",
    "initialCost": 0,
    "initialEarn": 202,
    "maxLevel": 1,
    "name": "Edcon 2024",
    "startTime": 1721767029000,
    "type": "hidden"
  },
  {
    "category": "Specials",
    "description": "Where you trade everything, from Bitcoin to coins you’ve never heard of. It’s a crypto treasure hunt!",
    "id": "pu_specials_binance_bonanza",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_binance_bonanza.png",
    "initialCost": 4400,
    "initialEarn": 60000,
    "maxLevel": 1,
    "name": "Binance Bonanza",
    "type": "hidden"
  },
  {
    "category": "Specials",
    "description": "Placeholder CEX text\n",
    "id": "pu_specials_cex_card",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_binance_bonanza.png",
    "initialCost": 0,
    "initialEarn": 0,
    "maxLevel": 1,
    "name": "TBD Cex card",
    "type": "hidden"
  },
  {
    "category": "Specials",
    "description": "Your first investment will earn you gemz passively while you are in game or 3hrs while offline",
    "id": "pu_specials_tutorial_1",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_tutorial_1.png",
    "initialCost": 2800,
    "initialEarn": 242,
    "maxLevel": 1,
    "name": "First Investment",
    "sort": 0,
    "type": "tutorial_1"
  },
  {
    "category": "Specials",
    "condition": "pu_specials_memetoken_madness_gift",
    "description": "Ride the meme coin rollercoaster! One minute you're mooning, the next you're holding bags. Buckle up and enjoy the chaos!",
    "endTime": 1728950401000,
    "id": "pu_specials_memetoken_madness",
    "image": "https://notgemz.cms.gemz.fun/media/powerups/pu_specials_memetoken_madness.png",
    "initialCost": 0,
    "initialEarn": 500,
    "maxLevel": 50,
    "name": "Meme Token Madness",
    "sort": 1,
    "startTime": 1722384000000,
    "type": "giftOnly"
  }
];
export default powerUpItems;
