// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Meme-feed-ads"

import { Value } from './memeFeedAds.types';

// prettier-ignore
const memeFeedAds: Value[] = [
  {
    "contentUrl": "https://notgemz-game.s3.us-east-1.amazonaws.com/media/notifications/invite_connection.jpg",
    "ctaLabel": "mfa_invite_cta",
    "description": "mfa_invite_description",
    "feature": "invite",
    "format": "image",
    "frequency": 10,
    "id": "invite",
    "lockInDuration": 750,
    "title": "mfa_invite_title"
  }
];
export default memeFeedAds;
